var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
        _c("div", { staticClass: "card overflow-hidden" }, [
          _c("div", { staticClass: "bg-gradient-primary" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "text-white p-4" }, [
                  _c("h5", { staticClass: "text-white" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("forgetPassword.header.title")) + " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("forgetPassword.header.description"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-5 align-self-center" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("@/assets/images/forget-password-image.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "card-body pt-0" }, [
            _c("div", [
              _c("div", { staticClass: "avatar-md profile-user-wid mb-4" }, [
                _c(
                  "span",
                  { staticClass: "avatar-title rounded-circle bg-light" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/Redem Icon.png"),
                        alt: "",
                        height: "38",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            this.tryingToReset === false
              ? _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c("p", { staticClass: "mb-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("forgetPassword.form.description")) +
                          " "
                      ),
                    ]),
                    _c(
                      "b-alert",
                      {
                        staticClass: "mb-4",
                        attrs: { variant: "danger", dismissible: "" },
                        model: {
                          value: _vm.isResetError,
                          callback: function ($$v) {
                            _vm.isResetError = $$v
                          },
                          expression: "isResetError",
                        },
                      },
                      [_vm._v(_vm._s(_vm.resetError))]
                    ),
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.clickResetSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "useremail" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("forgetPassword.form.email.label"))
                            ),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.submit && _vm.$v.email.$error,
                            },
                            attrs: {
                              type: "email",
                              id: "useremail",
                              placeholder: this.$t(
                                "forgetPassword.form.email.placeholder"
                              ),
                            },
                            domProps: { value: _vm.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.email = $event.target.value
                              },
                            },
                          }),
                          _vm.submit && _vm.$v.email.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.email.required
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "forgetPassword.form.email.requiredErrorMsg"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.$v.email.email
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "forgetPassword.form.email.notAnEmailErrorMsg"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "form-group row mb-0" }, [
                          _c("div", { staticClass: "col-12 text-right" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary w-md",
                                attrs: { type: "submit" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("forgetPassword.form.resetButton")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            this.tryingToReset === true
              ? _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c(
                      "b-alert",
                      {
                        staticClass: "mb-4",
                        attrs: { variant: "success", dismissible: "" },
                        model: {
                          value: _vm.isResetSuccess,
                          callback: function ($$v) {
                            _vm.isResetSuccess = $$v
                          },
                          expression: "isResetSuccess",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("forgetPassword.form.success.alert"))
                        ),
                      ]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("forgetPassword.form.success.description")
                        ) +
                        " "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "mt-5 text-center" }, [
          _c(
            "p",
            [
              _vm._v(" " + _vm._s(_vm.$t("forgetPassword.footer.text1")) + " "),
              _c(
                "router-link",
                {
                  staticClass: "font-weight-medium text-info",
                  attrs: { tag: "a", to: "/login" },
                },
                [_vm._v(_vm._s(_vm.$t("forgetPassword.footer.text2")))]
              ),
            ],
            1
          ),
          _c("p", [
            _vm._v(
              " © " +
                _vm._s(new Date().getFullYear()) +
                " Redem GmbH | made with "
            ),
            _c("i", { staticClass: "mdi mdi-heart text-danger" }),
            _vm._v(" in Austria "),
          ]),
        ]),
        _c("div", { staticClass: "mt-3 text-center" }, [
          _c(
            "span",
            {
              staticClass: "mr-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[0].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[0].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
            ]
          ),
          _vm._v(" • "),
          _c(
            "span",
            {
              staticClass: "ml-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[1].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[1].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }