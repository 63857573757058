<script>
import Layout from "@/router/layouts/auth";
import AuthService from "@/api/services/auth.service.js";
import ResponseStatus from "@/shared/enums/responseStatus.js";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";
import i18n from "@/i18n";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      email: "",
      error: null,
      tryingToReset: false,
      resetError: false,
      isResetError: false,
      isResetSuccess: false
    };
  },
  validations: {
    email: { required, email }
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    clickResetSubmit() {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();

      // if validation success
      if (this.$v.$invalid) {
        this.resetError = this.$t("forgetPassword.form.error.validation");
        this.isResetError = true;
      } else {
        this.tryToReset();
      }
    },
    async tryToReset() {
      // Reset the resetError if it existed.
      this.resetError = null;
      const response = await AuthService.sendPasswordResetEmail(this.email);
      if (response?.status === ResponseStatus.SUCCESS) {
        this.tryingToReset = true;
        this.isResetSuccess = true;
      } else {
        const errorCode = response.extensions.code;
        this.resetError = getErrorMessageByErrorCode(errorCode);
        this.isResetError = true;
      }
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-gradient-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-white p-4">
                  <h5 class="text-white">
                    {{ $t("forgetPassword.header.title") }}
                  </h5>
                  <p>{{ $t("forgetPassword.header.description") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-center">
                <img
                  src="@/assets/images/forget-password-image.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/Redem Icon.png" alt height="38" />
                </span>
              </div>
            </div>
            <div class="p-2" v-if="this.tryingToReset === false">
              <p class="mb-5">
                {{ $t("forgetPassword.form.description") }}
              </p>
              <b-alert
                v-model="isResetError"
                class="mb-4"
                variant="danger"
                dismissible
                >{{ resetError }}</b-alert
              >
              <form @submit.prevent="clickResetSubmit">
                <div class="form-group">
                  <label for="useremail">{{
                    $t("forgetPassword.form.email.label")
                  }}</label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    id="useremail"
                    :placeholder="
                      this.$t('forgetPassword.form.email.placeholder')
                    "
                    :class="{ 'is-invalid': submit && $v.email.$error }"
                  />
                  <div
                    v-if="submit && $v.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.email.required">{{
                      $t("forgetPassword.form.email.requiredErrorMsg")
                    }}</span>
                    <span v-if="!$v.email.email">{{
                      $t("forgetPassword.form.email.notAnEmailErrorMsg")
                    }}</span>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-12 text-right">
                    <button class="btn btn-primary w-md" type="submit">
                      {{ $t("forgetPassword.form.resetButton") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="p-2" v-if="this.tryingToReset === true">
              <b-alert
                v-model="isResetSuccess"
                class="mb-4"
                variant="success"
                dismissible
              >
                {{ $t("forgetPassword.form.success.alert") }}</b-alert
              >
              {{ $t("forgetPassword.form.success.description") }}
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            {{ $t("forgetPassword.footer.text1") }}
            <router-link
              tag="a"
              to="/login"
              class="font-weight-medium text-info"
              >{{ $t("forgetPassword.footer.text2") }}</router-link
            >
          </p>
          <p>
            © {{ new Date().getFullYear() }} Redem GmbH | made with
            <i class="mdi mdi-heart text-danger"></i> in Austria
          </p>
        </div>

        <!-- language Selector -->
        <div class="mt-3 text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor:pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor:pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
